/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../../generated/graphql-types";
import {
  Article,
  Hero,
  Statement,
  ValuesCollection,
} from "../../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../../layouts";
// import socialHead2 from "../../../images/Banner-Naccho.jpg";
import inline from "../../../images/raysofhope-inline.jpg";
import leftIcon from "../../../images/Left Button.svg";
import socialHead2 from "../../../images/linkedBaner.png";

const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const inlineImage = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { inline },
      srcSet: inline,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};

const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: { nodes },
    graphCmsSectorPage: sectorPage,
  },
}) => {
  nodes.map((n) => console.log(n.id));
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={sectorPage?.keywords}
      title="RAYS OF HOPE  | Partners in Performance | Global Management Consultancy"
      description={
        "Explore our case study about helping Door of Hope, an NPO working to save abandoned babies, by improving their donor strategy to increase funding."
      }
    >
      <Hero
        title="RAYS OF HOPE "
        video=""
        image={Image}
        short={true}
        headings=""
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <Statement
        title=""
        headline=""
        // cleanedMessage={rtfFromText(
        //   "We need to feel safe and supported to speak up, share our ideas and spark courageous conversations that help bring out the best in all of us. We know that our diverse backgrounds, experiences and perspectives help us to see things differently to the person next to us. As an organisation, we need that diversity of viewpoints and innovative thinking from our people to achieve the game-changing results we aim for when partnering with clients. Our simple belief is that we achieve better outcomes when people feel safe to be themselves. That is why we work hard to create a working environment in which all our people can belong, contribute and thrive. As a firm, we are committed to building an inclusive, diverse, flexible, and accessible culture in which all our people can unleash their full potential. As part of International Women's Day, we sat down with a few of our female leaders across the globe to bring these important conversations to the forefront. "
        // )}
        cleanedMessage={[
          {
            type: "paragraph",
            children: [
              {
                text: "Rays of Hope is a non-for-profit that initiates many outreach programs to one of the poorest areas in Alexandra, Johannesburg, for over 30 years.",
              },
            ],
          },
        ]}
        id={""}
        className=""
        Frompage="caseStudy"
      />
      <ValuesCollection
        key=""
        title="Organisational purpose and mission statement "
        heading={[
          {
            children: [
              {
                text: "The organisation aims to enable individuals and families to improve all aspects of their lives, thereby creating a lasting impact on the broader Alex community.",
              },
            ],
            type: "paragraph",
          },
          {
            children: [
              {
                text: "Rays of Hope started operating with one project in 1991 and has grown to a network of ten community-based programmes, focusing on education, vulnerable children, and work readiness. Over 2,800 people are impacted in total every year. As such, the organisation is hands-on in Alex, having developed close relationships with community leaders, the police, high schools, the Alexandra Clinic and the Department of Social Development, which has enabled it to leverage appropriate assistance when available and necessary. ",
              },
            ],
            type: "paragraph",
          },
        ]}
        butlletdesign="Need"
        // statements={[
        //   {
        //     id: "",
        //     title: "SolarBuddy works towards two goals: ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "1. Gift solar devices to children living in extreme poverty",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "2. Educate and inspire people to be agents of change to end extreme energy poverty by 2030 ",
        //     heading: [],
        //   },
        // ]}
      />
      {/* <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        articlewithContent="YES"
        detailsRTF={[
          {
            children: [
              {
                text: "An open‑pit gold miner had just completed a transition to owner‑mining, and faced many operational challenges including old mining equipment and a high‑maintenance processing plant.",
              },
            ],
            type: "heading-three",
          },
          {
            children: [
              {
                text: "Unit costs were under severe pressure: fixed costs increased by 100% following the transition; rising fuel and power costs; main pit getting deeper and haul distances longer.",
              },
            ],
            type: "paragraph",
          },
        ]}
        // statements={[
        //   {
        //     id: "",
        //     title:
        //       "Survey data indicated insufficient digital readiness: approximately a quarter of members do not have nbn access and rural and remote Indigenous communities experience pronounced effects of reduced connectivity and reduced access to technology and digital skills, impeding access to quality healthcare. ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "A compelling business case was needed to secure the Federal government funding required to improve the connectivity and digital literacy for NACCHO members to facilitate telehealth options. ",
        //     heading: [],
        //   },
        // ]}
        heading={[
          {
            children: [
              {
                text: "Rays of Hope was able to assist over 2,400 Alexandra residents to stay warm during the harsh winter in 2021 and they are aiming to surpass the figure this year by providing much-needed aid to those hard hit by the pandemic, lockdown measures, rising cost of living and civil unrest ",
              },
            ],
            type: "paragraph",
          },
        ]}
        Articletitle="Impact achieved  "
      /> */}
      <ValuesCollection
        key=""
        title="What we did  "
        className="bg-light-blue pt-5"
        changeDesign="YES"
        butlletdesign="YES"
        styleclass="pt-8px"
        statements={[
          {
            id: "",
            title:
              "Donated blankets, winter clothes, non-perishable food, hot water bottles and anything to keep the people of Alex Warm via Rays of Hope’s annual #WarmUpAlex campaign in July 2022  ",
            heading: [],
          },
        ]}
      />
      {/* <ValuesCollection
        key=""
        title="Impact achieved "
        changeDesign="YES"
        styleclass="pt-7px"
        heading={[
          {
            children: [
              {
                text: "Rays of Hope was able to assist over 2,400 Alexandra residents to stay warm during the harsh winter in 2021 and they are aiming to surpass the figure this year by providing much-needed aid to those hard hit by the pandemic, lockdown measures, rising cost of living and civil unrest ",
              },
            ],
            type: "paragraph",
          },
        ]}
        // statements={[
        //   {
        //     id: "",
        //     title:
        //       "Current and future state planning – Prepared a view of current and desired future state with NACCHO and nbn (considering connectivity infrastructure and digital healthcare requirements) ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Development of prioritisation criteria – Developed framework for assessing which ACCHOs to prioritise for connectivity and digital capability uplift investment to ensure targeted value for money ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Connectivity and toolset requirements – Defined must have tools, services, connectivity improvements ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "Cost benefit analysis – Summarised options for capability uplift, benefits and outcomes, and with breakdown of cost estimates ",
        //     heading: [],
        //   },
        // ]}
      /> */}
      <Article
        side="right"
        id={""}
        image={inlineImage}
        alt={""}
        articlewithContent="YES"
        detailsRTF={[
          {
            children: [
              {
                text: "An open‑pit gold miner had just completed a transition to owner‑mining, and faced many operational challenges including old mining equipment and a high‑maintenance processing plant.",
              },
            ],
            type: "heading-three",
          },
          {
            children: [
              {
                text: "Unit costs were under severe pressure: fixed costs increased by 100% following the transition; rising fuel and power costs; main pit getting deeper and haul distances longer.",
              },
            ],
            type: "paragraph",
          },
        ]}
        // statements={[
        //   {
        //     id: "",
        //     title:
        //       "Survey data indicated insufficient digital readiness: approximately a quarter of members do not have nbn access and rural and remote Indigenous communities experience pronounced effects of reduced connectivity and reduced access to technology and digital skills, impeding access to quality healthcare. ",
        //     heading: [],
        //   },
        //   {
        //     id: "",
        //     title:
        //       "A compelling business case was needed to secure the Federal government funding required to improve the connectivity and digital literacy for NACCHO members to facilitate telehealth options. ",
        //     heading: [],
        //   },
        // ]}
        heading={[
          {
            children: [
              {
                text: "Rays of Hope was able to assist over 2,400 Alexandra residents to stay warm during the harsh winter in 2021 and they are aiming to surpass the figure this year by providing much-needed aid to those hard hit by the pandemic, lockdown measures, rising cost of living and civil unrest ",
              },
            ],
            type: "paragraph",
          },
        ]}
        Articletitle="Impact achieved  "
      />
      <p className="col-span-12 col-start-2 text-center p-20px ga-mt--30">
        To learn more or to contribute to this great organisation, please visit
        &nbsp;
        <b>
          <a
            href="https://raysofhope.co.za/warmupalex/ "
            className="text-dark-blue"
          >
            https://raysofhope.co.za/warmupalex/
          </a>
        </b>
      </p>
      <>
        <section className="py-section page-grid bg-white-1">
          <a
            href="/en/our-social-values"
            className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6 bg"
          >
            <img
              src={leftIcon}
              alt="Left"
              className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
            />
            Our social values
          </a>
        </section>
      </>
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
